.debugPanel {
    position: fixed;
    background-color: "";
    padding: 4px;
    width: 24px;
    bottom: 1%;
    right: 0;
    z-index: 9999;
    
    .listItem {
        opacity: 0.85;
        cursor: pointer;
    }

    .listItem:hover {
        opacity: 1;
    }
}
