html,
body {
  margin: 0;
  padding: 0;
}

.LayoutPreview {
  display: flex;
  flex-flow: column;
  height: 100vh; /* 100vh - good, 100% - breaks all layout! */
  overflow: hidden; /* no scrollbars */
}

.LayoutPreview .header {
  flex: 0 1 50px;
}

.LayoutPreview .panel {
  flex: 1 1 auto; /* grow full height except header */
  overflow: hidden; /* no scrollbars */
  display: flex;
  flex-flow: row;
}

.panel .main {
  flex: 1 1 55%; /* grow full height except footer */
  overflow-x: auto; /* scrollbars */
  overflow-y: scroll;
}

.panel .preview {
  flex: 1 1 45%; /* grow full height except footer */
  overflow: auto; /* scrollbars */
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.LayoutPreview .footer {
  flex: 0 1 50px;
}
