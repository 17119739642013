.AdminForm {
    margin-top: 50px;
    margin-bottom: 50px;
}

.AdminForm__table {
    margin: 20px;
    display: grid;
    grid-template-columns: 1fr;
    text-align: left;
}

.AdminForm__row {
    padding: 7px 7px 0px 7px;
    display: flex;
}

.AdminForm__row > * {
    flex: 1 1 auto;
}
