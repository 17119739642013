html {
  margin: 0;
  padding: 0;
  overflow: auto !important; /* prevent MUI twitch on popup */
}

body {
  margin: 0;
  padding: 0;
  padding-right: 0 !important; /* prevent MUI twitch on popup */
}

.LayoutMain {
    display: flex; 
    flex-direction: column;
    min-height: 100vh;
}

.LayoutMain .header {
    position: fixed;
    z-index: 1250;
    width: 100%;
}

.LayoutMain .main {
    margin-top: 50px;
}

.LayoutMain .footer {
    margin-top: auto;
}
