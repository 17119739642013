.table{
    display: table;
    width: 100%;
}

.table .tbody {
    display: table-row-group;
}

.table .row {
    display: table-row;
    cursor: grab;
}

.table .row .col,
.table .row .head,
.table .row .border {
    display: table-cell;
    vertical-align: top;
    height: 100%;
}

.table .row .head,
.table .row .border {
    border: solid #bbb 1px;
    border-right: none;
}

.table .row .head {
    background-color: #eaeaea;
    border-bottom: none;
}

.table .row .border {
    border-top: none;
}

.table .row:first-child .head:nth-child(2) {
    border-radius: 4px 0px 0px 0px;
}

.table .row:first-child .head:nth-last-child(2) {
    border-right: solid #bbb 1px;
    border-radius: 0px 4px 0px 0px;
}

.table .row:last-child .border:nth-child(2) {
    border-radius: 0px 0px 0px 4px;
}

.table .row:last-child .border:nth-last-child(2) {
    border-radius: 0px 0px 4px 0px;
}

.table .row .border:nth-last-child(2) {
    border-right: solid #bbb 1px;
}

.table .row:first-child .border {
    border-top: solid #bbb 1px;
}
