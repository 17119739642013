@value colors: "./../../constants/index.css";
@value color_main, color_grey from colors;

.doc_icon {
    color: #204070;
}

.table {
    margin: 20px;
    text-align: left;
    font-size: 14px;
}

.info {
    margin-left: 22px;
    text-align: left;
    font-size: 14px;
}

.table th {
    color: #f0f0f0;
    background-color: #909090;
    font-weight: normal;
    padding: 3px;
}

tr:nth-child(even) {
    background-color: #f3f3f3;
}

.table span {
    font-size: 12px;
}

.info span {
    font-size: 12px;
}

.xxHelp {
    color: color_grey;
}

.xxHelp:hover {
    color: color_main;
}
