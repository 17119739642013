.DocForm {
    margin-top: 50px;
    margin-bottom: 50px;
}

.Card {
    position: relative;
}

.table {
    margin: 20px;
}

.table__row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.table__head {
    padding: 7px;
    flex-grow: 1;
    background-color: #F0F0F0;
    border-radius: 4px;
    margin: 6px;
    margin-bottom: 0px;
}

.padding {
    padding: 7px;
    flex-grow: 1;
}

.row {
    display: flex;
    flex-direction: row;
}

.col {
    display: flex;
    flex-direction: column;
}

.smallText {
    font-size: 14px;
}

.button100 {
    width: 100%;
    max-height: 36px;
}

