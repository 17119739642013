.Movable {
}

.MovableList {
    padding: 0;
    z-index: 1250;
}

.MovableItem {
    list-style-type: none;
    cursor: grab;
    z-index: 1300;
}
