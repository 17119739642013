.xxGridActionPanel {
    margin-left: auto;
    margin-right: auto;
    width: 93%;
}

.HistoryForm {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}
