@value colors: "./../../constants/index.css";
@value color_main, color_grey from colors;

.change {
    /*background-color: #e0f0f8;*/
}

.doc_icon {
    color: #204070;
}

.table {
    margin: 20px;
    text-align: left;
    font-size: 14px;
    width: calc(100% - 36px);
}

.info {
    margin-left: 22px;
    text-align: left;
    font-size: 14px;
}

.table th {
    color: #f0f0f0;
    background-color: #909090;
    font-weight: normal;
    padding: 3px;
}

tr:nth-child(even) {
    background-color: #f3f3f3;
}

.table td {
    overflow-wrap: anywhere;
    hyphens: auto; /*не работает*/
}

.table span {
    font-size: 12px;
}

.info span {
    font-size: 12px;
}


[name=dateFrom] {
  width: 170px !important;
}
[name=dateTo] {
  width: 170px !important;
}
[name=limit] {
  width: 227px !important;
}
[name=update] {
  width: 170px;
  margin-top: 7px !important;
}

.xxHelp {
    color: color_grey;
}

.xxHelp:hover {
    color: color_main;
}


.xxEdit {
  /*display: none;*/
  opacity: 0.25;
}

.xxAboveEdit {
  width: 30px;
  height: 30px;
}

.xxAboveEdit:hover .xxEdit {
  /*display: block;*/
  opacity: 1;
}

