@font-face {
  font-family: "YS Text";
  src: url("//yastatic.net/islands/_/PyVcRbwHetz0gOVWLonWH7Od8zM.woff2") format("woff2")
      ,url("//yastatic.net/islands/_/bIx8jOfCEfR-mECoDUEZywDBuHA.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: "YS Text", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
}
