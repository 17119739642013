.Movable {
}

.MovableList {
    padding: 0;
}

.MovableItem {
    padding: 1em;
    margin: 0.5em 0em;
    list-style-type: none;
    border: 1px solid #CCC;
    box-shadow: 1px 1px #DDD;
    border-radius: 5px;
    cursor: grab;
}
